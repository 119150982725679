import React, { Component } from "react";
import { graphql } from "gatsby";
import { REVIEWS_CONTENT } from '../../content/reviews';
import Header from "../components/header/header";
import Head from "../components/head/head";
import Footer from "../components/footer/footer";
import App from '../components/app';
import CookieConsent from "../components/cookieConsent/cookieConsent";
import { getParams } from '../utils/analytics/UtmReferrers';
require('../styles/global.css');

class IndexPage extends Component {

  state = {
    language: 'zh-CN'
  }

  handler(field, value) {
    this.setState({
      [field]: value
    })
  }


  render() {
    let domain;
    let origin;
    const digitalDataTitle = 'create-account';

    if (typeof window !== 'undefined') {
      /**
       * pulls domain buyer name
       */
      domain = window.location.href;

      //passes domain to getParams util to set UTM parameters for Google Analytics to pick up
      getParams(domain);
    }
    const nodes = this.props.data.allBuyerList.edges[0].node;
    const relationshipData = nodes.relationship;
    let buyerData = nodes.buyer;
    let localeContent = nodes.content[0];
    var divisionUuid = relationshipData[0].divisionUuid;
    var localeForm = nodes.formContent[0];
    var langCode = this.state.language;
    let localeReviews = REVIEWS_CONTENT[0];
    let reviewsObj = REVIEWS_CONTENT;

    localeContent = nodes.content.find(
      content => content.contentLocale === langCode
    );

    if (!localeContent) {
      localeContent = nodes.content.find(
        content => content.contentLocale.split('-')[0] === langCode
      );
    }

    localeForm = nodes.formContent.find(
      formContent => formContent.contentLocale === langCode
    );

    if (!localeForm) {
      localeForm = nodes.formContent.find(
        formContent => formContent.contentLocale.split('-')[0] === langCode.split('-')[0]
      );
    }

    localeReviews = reviewsObj.find(review => review.locale === this.state.language);

    return (
      <div id="buyer-microsite">
        <Head
          buyer={buyerData}
          locale={this.state.language} 
          digitalDataTitle={digitalDataTitle} />
        <Header
          handler={this.handler.bind(this)}
          buyer={buyerData}
          localeContent={relationshipData}
          phone={localeContent ? localeContent.localePhoneNumber : nodes.content[0].localePhoneNumber}
          locale={this.state.language}
          content={localeContent ? localeContent : nodes.content[0]}
          params={this.props.location} />
        <App
          buyer={buyerData}
          division={divisionUuid}
          location={origin}
          localeContent={relationshipData}
          content={localeContent ? localeContent : nodes.content[0]}
          form={localeForm}
          propLocation={this.props.location.search}
          reviews={localeReviews ? localeReviews : 'no-reviews'}
          formSubmission={fields => this.onSubmit(fields).bind(this)} />
        <Footer
          buyerInfo={buyerData}
          content={localeContent ? localeContent : nodes.content[0]} />
        <CookieConsent content={localeContent ? localeContent : nodes.content[0]} />
      </div>
    );
  }
}

export default IndexPage

export const query = graphql`
query($permalink: String ) {
  allBuyerList(filter: {buyer: {permalink: {eq: $permalink}}}) {
    edges {
      node {
        ...Buyer
        ...Relationship
        ...Content
        ...Form
        ...ThankYou
      }
    }
  }
}
`
